import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import { Offcanvas, Alert } from 'react-bootstrap';
import { NumericFormat } from 'react-number-format';
import { createProducto } from '../../services/ProductosService';
import Select from 'react-select';
import { getComercios } from '../../services/ComerciosService';
import { getSalesPoints } from '../../services/PuntosVentaService';

const ProductoCanvas = forwardRef((props, ref) => {
    const [name, setName] = useState('');
    const [barcode, setBarcode] = useState('');
    const [quantity, setQuantity] = useState(0);
    const [purchasePrice, setPurchasePrice] = useState('');
    const [salePrice, setSalePrice] = useState('');
    const [colors, setColors] = useState([]); // Iniciar con un array vacío
    const [currentColor, setCurrentColor] = useState(''); // Iniciar sin color seleccionado
    const [sizes, setSizes] = useState([]);
    const [unitOfMeasurement, setUnitOfMeasurement] = useState(null);
    const [logo, setLogo] = useState(null);
    const [commerceId, setCommerceId] = useState(null);
    const [salesPoints, setSalesPoints] = useState([]); // Ahora este array contiene múltiples puntos de venta
    const [commerces, setCommerces] = useState([]);
    const [salesPointsOptions, setSalesPointsOptions] = useState([]);
    const [addProducto, setAddProducto] = useState(false);
    const [loading, setLoading] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertVariant, setAlertVariant] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [errors, setErrors] = useState({});

    const unitOptions = [
        { value: 'gramos', label: 'Gramos' },
        { value: 'kilogramos', label: 'Kilogramos' },
        { value: 'libras', label: 'Libras' },
        { value: 'litros', label: 'Litros' },
        { value: 'mililitros', label: 'Mililitros' },
        { value: 'unidades', label: 'Unidades' }
    ];

    const sizeOptions = [
        { value: 'XS', label: 'XS' },
        { value: 'S', label: 'S' },
        { value: 'M', label: 'M' },
        { value: 'L', label: 'L' },
        { value: 'XL', label: 'XL' },
        { value: 'XXL', label: 'XXL' },
        { value: 'XXXL', label: 'XXXL' },
        { value: 'Grande', label: 'Grande' },
        { value: 'Mediano', label: 'Mediano' },
        { value: 'Pequeño', label: 'Pequeño' }
    ];

    useImperativeHandle(ref, () => ({
        showProductoModal() {
            setAddProducto(true);
            loadCommerces();
        }
    }));

    const loadCommerces = async () => {
        try {
            const userDetails = JSON.parse(localStorage.getItem('userDetails'));
            const adminId = userDetails?.userData?.id || null;
            const response = await getComercios(adminId);
            const activeCommerces = response.data.map(commerce => ({
                value: commerce.id,
                label: commerce.name,
            }));
            setCommerces(activeCommerces);
        } catch (error) {
            console.error('Error al cargar los comercios:', error);
        }
    };

    const loadSalesPoints = async (commerceId) => {
        try {
            const response = await getSalesPoints(commerceId);
            setSalesPointsOptions(response.data.map(salesPoint => ({
                value: salesPoint.id,
                label: salesPoint.name,
            })));
        } catch (error) {
            console.error('Error al cargar los puntos de venta:', error);
        }
    };

    useEffect(() => {
        if (commerceId) {
            loadSalesPoints(commerceId);
        }
    }, [commerceId]);

    const handleFileChange = (e) => {
        setLogo(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setErrors({});
    
        try {
            const formData = new FormData();
            formData.append('name', name.toLowerCase().trim());
            formData.append('barcode', barcode);
            formData.append('quantity', Number(quantity));
            formData.append('purchasePrice', parseFloat(purchasePrice.replace(/[^0-9.-]+/g, "")));
            formData.append('salePrice', parseFloat(salePrice.replace(/[^0-9.-]+/g, "")));
            
            // Si hay colores seleccionados, agregar al formData
            if (colors.length > 0) {
                colors.forEach(color => formData.append('colors', color));
            } else {
                formData.append('colors', []); // Enviar array vacío si no hay colores
            }
            
            sizes.forEach(size => formData.append('sizes', size.value)); // Agregar los tamaños seleccionados
            if (unitOfMeasurement) formData.append('unitOfMeasurement', unitOfMeasurement.value);
            formData.append('commerceId', commerceId);
    
            // Agregar múltiples puntos de venta como entradas separadas
            // Asegurar que salesPointIds se envíe correctamente en FormData
            salesPoints.forEach(salesPoint => {
                formData.append('salesPointIds[]', salesPoint.value);
            });
    
            if (logo) {
                formData.append('images', logo);
            }
    
            const response = await createProducto(formData);
    
            setAlertMessage('Producto creado exitosamente');
            setAlertVariant('success');
            setShowAlert(true);
            props.onProductoCreated(response.data);
            
            // Limpiar el formulario
            limpiarFormulario();

            setTimeout(() => {
                setAddProducto(false);
            }, 1000);
        } catch (error) {
            console.error('Error creando el producto:', error);
            if (error.response?.data?.message) {
                const validationErrors = error.response.data.message.reduce((acc, curr) => {
                    const field = curr.split(' ')[0];
                    acc[field] = true;
                    return acc;
                }, {});
                setErrors(validationErrors);
            }
            setAlertMessage('Hubo un error creando el producto. Intente nuevamente.');
            setAlertVariant('danger');
            setShowAlert(true);
        } finally {
            setLoading(false);
        }
    };

    const limpiarFormulario = () => {
        setName('');
        setBarcode('');
        setQuantity(0);
        setPurchasePrice('');
        setSalePrice('');
        setColors([]);
        setCurrentColor('');
        setSizes([]);
        setUnitOfMeasurement(null);
        setLogo(null);
        setCommerceId(null);
        setSalesPoints([]);
        setSalesPointsOptions([]);
    };

    const handleAddColor = () => {
        if (currentColor && !colors.includes(currentColor)) {
            setColors([...colors, currentColor]);
            setCurrentColor(''); // Limpiar el campo de color después de agregarlo
        }
    };

    const handleRemoveColor = (color) => {
        setColors(colors.filter(c => c !== color));
    };

    return (
        <>
            <Offcanvas show={addProducto} onHide={() => setAddProducto(false)} className="offcanvas-end customeoff" placement='end'>
                <div className="offcanvas-header">
                    <h5 className="modal-title">{props.Title}</h5>
                    <button type="button" className="btn-close" onClick={() => setAddProducto(false)}>
                        <i className="fa-solid fa-xmark"></i>
                    </button>
                </div>
                <div className="offcanvas-body">
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label className="form-label">Nombre del Producto <span className="text-danger">*</span></label>
                            <input
                                type="text"
                                className={`form-control ${errors.name ? 'border-danger' : ''}`}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Código de Barras <span className="text-danger">*</span></label>
                            <input
                                type="text"
                                className={`form-control ${errors.barcode ? 'border-danger' : ''}`}
                                value={barcode}
                                onChange={(e) => setBarcode(e.target.value)}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Cantidad <span className="text-danger">*</span></label>
                            <input
                                type="number"
                                className={`form-control ${errors.quantity ? 'border-danger' : ''}`}
                                value={quantity}
                                onChange={(e) => setQuantity(e.target.value)}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Precio de compra <span className="text-danger">*</span></label>
                            <NumericFormat
                                className={`form-control ${errors.purchasePrice ? 'border-danger' : ''}`}
                                value={purchasePrice}
                                thousandSeparator="."
                                decimalSeparator="," 
                                prefix="$"
                                onValueChange={(values) => setPurchasePrice(values.value)}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Precio de venta <span className="text-danger">*</span></label>
                            <NumericFormat
                                className={`form-control ${errors.salePrice ? 'border-danger' : ''}`}
                                value={salePrice}
                                thousandSeparator="."
                                decimalSeparator="," 
                                prefix="$"
                                onValueChange={(values) => setSalePrice(values.value)}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label>Colores (opcional)</label>
                            <div className="d-flex align-items-center mb-2">
                                <input
                                    type="color"
                                    value={currentColor}
                                    onChange={(e) => setCurrentColor(e.target.value)}
                                    className="form-control form-control-color"
                                />
                                <button type="button" className="btn btn-sm btn-primary ms-2" onClick={handleAddColor}>
                                    +
                                </button>
                            </div>
                            <div className="d-flex">
                                {colors.map((color, index) => (
                                    <div key={index} className="d-flex align-items-center me-3">
                                        <div
                                            style={{
                                                width: '20px',
                                                height: '20px',
                                                backgroundColor: color,
                                                border: '1px solid #000',
                                                marginRight: '10px'
                                            }}
                                        ></div>
                                        <span>{color}</span>
                                        <button
                                            type="button"
                                            className="btn btn-danger btn-sm ms-2"
                                            onClick={() => handleRemoveColor(color)}
                                        >
                                            x
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="mb-3">
                            <label>Tamaños (opcional)</label>
                            <Select
                                value={sizes}
                                onChange={(selectedSizes) => setSizes(selectedSizes)}
                                options={sizeOptions}
                                isMulti
                                placeholder="Seleccionar Tamaños"
                                className="basic-multi-select"
                                classNamePrefix="select"
                            />
                        </div>
                        <div className="mb-3">
                            <label>Unidad de Medida (opcional)</label>
                            <Select
                                value={unitOfMeasurement}
                                onChange={(selectedOption) => setUnitOfMeasurement(selectedOption)}
                                options={unitOptions}
                                placeholder="Seleccionar Unidad de Medida"
                                className="basic-single"
                                classNamePrefix="select"
                                isClearable
                            />
                        </div>
                        <div className="mb-3">
                            <label>Comercio</label>
                            <Select
                                value={commerces.find(c => c.value === commerceId)}
                                onChange={(selectedOption) => setCommerceId(selectedOption?.value || null)}
                                options={commerces}
                                placeholder="Seleccionar Comercio"
                                className={`basic-single ${errors.commerceId ? 'border-danger' : ''}`}
                                classNamePrefix="select"
                                isClearable
                            />
                        </div>
                        <div className="mb-3">
                            <label>Puntos de Venta</label>
                            <Select
                                value={salesPoints}
                                onChange={setSalesPoints}
                                options={salesPointsOptions}
                                placeholder="Seleccionar Puntos de Venta"
                                className={`basic-single ${errors.salesPointId ? 'border-danger' : ''}`}
                                classNamePrefix="select"
                                isClearable
                                isMulti
                            />
                        </div>
                        <div className="mb-3">
                            <label>Imagen del Producto (opcional)</label>
                            <input type="file" className="form-control" onChange={handleFileChange} />
                        </div>

                        {alertMessage && (
                            <Alert variant={alertVariant} className={`fade ${showAlert ? 'show' : ''}`}>
                                {alertMessage}
                            </Alert>
                        )}

                        <div className="mb-5 mt-3">
                            <button type="submit" className="btn btn-primary" disabled={loading}>
                                {loading ? 'Creando...' : 'Crear Producto'}
                            </button>
                            <button type="button" className="btn btn-secondary ms-2" onClick={() => setAddProducto(false)}>
                                Cancelar
                            </button>
                        </div>
                    </form>
                </div>
            </Offcanvas>
        </>
    );
});

export default ProductoCanvas;
