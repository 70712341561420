import React, { useState } from "react";
import Select from 'react-select';
import { NumericFormat } from 'react-number-format';
import { createSale, findCustomerByDni } from "../../../../services/VentaService";
import { Modal, Button, Alert, Spinner } from "react-bootstrap";
import FacturaImprimible from './FacturaImprimible'; // Asegúrate de crear este componente para la impresión
import FacturaPOS from "./FacturaPOS"; // Importamos FacturaPOS

// Opciones para Select2 de la Forma de Facturación
const facturacionOptions = [
  { value: 'interna', label: 'Factura Interna' },
  { value: 'electronica', label: 'Factura Electrónica' }
];

// Opciones para Select2 de los tipos de documentos en Colombia
const tiposDocumentoOptions = [
  { value: '', label: 'Seleccionar tipo de documento' },
  { value: 'CC', label: 'Cédula de Ciudadanía' },
  { value: 'TI', label: 'Tarjeta de Identidad' },
  { value: 'CE', label: 'Cédula de Extranjería' },
  { value: 'NIT', label: 'Número de Identificación Tributaria' },
  { value: 'PP', label: 'Pasaporte' }
];

// Opciones para Select2 de los tipos de pago
const tiposPagoOptions = [
  { value: '', label: 'Seleccionar forma de pago' },
  { value: 'efectivo', label: 'Efectivo' },
  { value: 't. debito', label: 'Tarjeta Débito' },
  { value: 't. credito', label: 'Tarjeta Crédito' },
  { value: 'transferencia', label: 'Transferencia' },
  { value: 'nequi', label: 'Nequi' },
  { value: 'daviplata', label: 'Daviplata' }
];

const FormularioVenta = ({
  carrito,
  setCarrito,
  total,
  subtotal,
  impuestos,
  setImpuestos,
  setSubtotal,
  setTotal,
  commerceId,
  salesPointId,
  userData,
  assignedUser,
  setAssignedUser,
  commerceData,
  salePointData = {}
}) => {
  const [formaFacturacion, setFormaFacturacion] = useState(facturacionOptions[0]);
  const [tipoPago, setTipoPago] = useState(tiposPagoOptions[0]);
  const [cliente, setCliente] = useState({
    nombre: "",
    telefono: "",
    correo: "",
    tipoDocumento: tiposDocumentoOptions[0],
    documento: ""
  });
  const [errores, setErrores] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingVenta, setLoadingVenta] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [venta, setVenta] = useState(null);
  const [alerta, setAlerta] = useState({ type: '', message: '' });
  const [finalizarDisabled, setFinalizarDisabled] = useState(false);
  const [invoicePOS, setInvoicePOS] = useState(null); // ✅ Nuevo estado para la factura POS
  const [montoRecibido, setMontoRecibido] = useState('');
  const [cambio, setCambio] = useState('');
  const [mostrarInputsPago, setMostrarInputsPago] = useState(false);

  const validarCorreo = (correo) => {
    const regexCorreo = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regexCorreo.test(correo);
  };

  const validarTelefono = (telefono) => {
    return telefono.length >= 10;
  };

  const handleBuscarCliente = async () => {
    if (!cliente.documento) {
      setErrores((prev) => ({ ...prev, documento: "Ingrese un número de documento" }));
      return;
    }

    setErrores({});
    setLoading(true);

    try {
      const foundCliente = await findCustomerByDni(cliente.documento, commerceId);
      if (foundCliente) {
        setCliente({
          nombre: foundCliente.name,
          telefono: foundCliente.phone,
          correo: foundCliente.email,
          tipoDocumento: tiposDocumentoOptions.find(opt => opt.value === foundCliente.documentType),
          documento: foundCliente.document
        });
      } else {
        setErrores((prev) => ({ ...prev, documento: "Número de documento no registrado en el comercio" }));
        setCliente({
          nombre: "",
          telefono: "",
          correo: "",
          tipoDocumento: tiposDocumentoOptions[0],
          documento: cliente.documento
        });
      }
    } catch (error) {
      console.error("Error al buscar el cliente:", error);
    } finally {
      setLoading(false);
    }
  };

  const crearVenta = () => {
    setErrores({});
    if (cliente.correo && !validarCorreo(cliente.correo)) {
      setErrores((prev) => ({ ...prev, correo: "Correo electrónico inválido" }));
      return;
    }
    if (cliente.telefono && !validarTelefono(cliente.telefono)) {
      setErrores((prev) => ({ ...prev, telefono: "El número de teléfono debe tener al menos 10 dígitos" }));
      return;
    }

    const nuevaVenta = {
      user: userData,
      commerce: {
        id: commerceId,
        name: JSON.parse(commerceData).label
      },
      salesPoint: {
        id: salesPointId,
        name: JSON.parse(salePointData).label
      },
      InvoiceType: formaFacturacion.value,
      paymentType: tipoPago.value, // Agregar el tipo de pago al objeto de la venta
      dataItems: carrito.map((item, index) => ({
        ItemNumber: index + 1,
        ItemType: item.type === "product" ? "Product" : "Service",
        name: item.name,
        quantity: item.cantidad || 1,
        disccount: item.descuento || 0,
        unitValue: parseFloat(item.salePrice) || 0,
        baseValue: parseFloat(item.purchasePrice) || 0,
        tax: {
          idTax: item.selectedTax?.value || "default",
          name: item.selectedTax?.label || "0%",
          percentage: item.selectedTax?.percentage || "0"
        },
        Total: (item.cantidad || 1) * (parseFloat(item.salePrice) || 0),
        ...(item.type === "product" && {
          idProduct: item.id,
          barcode: item.barcode || "-"
        }),
        ...(item.type === "service" && {
          idService: item.id,
          commisionPercetage: parseFloat(item.commissionPercentage) || 0,
          Employee: assignedUser[item.id] ? assignedUser[item.id] : undefined
        })
      })),
      customer: cliente,
      Subtotal: subtotal,
      Taxes: impuestos,
      Total: total,
      createAt: new Date().toISOString()
    };

    setVenta(nuevaVenta);
    setShowModal(true);
    setFinalizarDisabled(false);
    setMostrarInputsPago(true); // ✅ Ahora los inputs aparecerán cuando se cree la venta
  };

  const guardarVenta = async () => {
    setLoadingVenta(true);
      try {
          if (venta) {
              const response = await createSale(venta); // ⬅️ Guardamos la venta en el backend
              setAlerta({ type: 'success', message: 'Venta finalizada con éxito' });
              setFinalizarDisabled(true);
              setInvoicePOS(response.data); // ✅ Guardamos la respuesta en invoicePOS
              // setMostrarInputsPago(true); // ✅ Mostramos los inputs de pago
          }
      } catch (error) {
          setAlerta({ type: 'danger', message: 'Hubo un error al finalizar la venta' });
          console.error("Error al guardar la venta:", error);
      } finally {
          setLoadingVenta(false);
      }
  };
  
  const limpiarVenta = () => {
    setCliente({
      nombre: "",
      telefono: "",
      correo: "",
      tipoDocumento: tiposDocumentoOptions[0],
      documento: ""
    });
    setCarrito([]);
    setAssignedUser({});
    setImpuestos(0);
    setSubtotal(0);
    setTotal(0);
    setShowModal(false);
    setInvoicePOS(null); // Oculta FacturaPOS al limpiar la venta
    setAlerta({ type: '', message: '' });
    setMontoRecibido(''); // ✅ Resetear monto recibido
    setCambio(''); // ✅ Resetear cambio
    setMostrarInputsPago(false); // ✅ Ocultar inputs
  };

  const imprimirVenta = () => {
    window.print();
  };

  const cerrarModal = () => {
    setShowModal(false);
    setInvoicePOS(null); // Oculta FacturaPOS al limpiar la venta
  };

  return (
    <div className="formulario-venta mt-4">
      <h4>Opciones de Venta</h4>

      <div className="row">
        <div className="col-md-6">
          <div className="form-group mt-3">
            <label>Forma de Facturación</label>
            <Select
              value={formaFacturacion}
              onChange={(selectedOption) => setFormaFacturacion(selectedOption)}
              options={facturacionOptions}
              classNamePrefix="select2"
            />
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group mt-3">
            <label>Tipo de Pago</label>
            <Select
              value={tipoPago}
              onChange={(selectedOption) => setTipoPago(selectedOption)}
              options={tiposPagoOptions}
              classNamePrefix="select2"
            />
          </div>
        </div>

        <div className="col-md-12">
          <div className="form-group mt-3">
            <label>Número de Documento</label>
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder="Número de Documento"
                value={cliente.documento}
                onChange={(e) => setCliente({ ...cliente, documento: e.target.value })}
                onKeyPress={(e) => e.key === 'Enter' && handleBuscarCliente()}
              />
              <button className="btn btn-secondary" onClick={handleBuscarCliente}>
                <i className="fa-sharp fa-light fa-magnifying-glass"></i>
              </button>
            </div>
            {errores.documento && <div className="text-danger mt-1">{errores.documento}</div>}
            {loading && <div className="mt-2">Buscando cliente...</div>}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <div className="form-group mt-3">
            <label>Tipo de Documento</label>
            <Select
              value={cliente.tipoDocumento}
              onChange={(selectedOption) => setCliente({ ...cliente, tipoDocumento: selectedOption })}
              options={tiposDocumentoOptions}
              classNamePrefix="select2"
            />
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group mt-3">
            <label>Nombre del Cliente</label>
            <input
              type="text"
              className="form-control"
              placeholder="Nombre del Cliente"
              value={cliente.nombre}
              onChange={(e) => setCliente({ ...cliente, nombre: e.target.value })}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <div className="form-group mt-3">
            <label>Teléfono</label>
            <NumericFormat
              format="+57 (###) ###-####"
              mask="_"
              className="form-control"
              placeholder="Teléfono (Ej: +57 (305) 896-9053)"
              value={cliente.telefono}
              onValueChange={(values) => setCliente({ ...cliente, telefono: values.value })}
            />
            {errores.telefono && <div className="text-danger mt-1">{errores.telefono}</div>}
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group mt-3">
            <label>Correo Electrónico</label>
            <input
              type="email"
              className="form-control"
              placeholder="Correo (Ej: correo@ejemplo.com)"
              value={cliente.correo}
              onChange={(e) => setCliente({ ...cliente, correo: e.target.value })}
            />
            {errores.correo && <div className="text-danger mt-1">{errores.correo}</div>}
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={cerrarModal} centered className="modal-lg">
        <Modal.Header closeButton>
          <Modal.Title>Creación Venta Exitosa</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {venta && <FacturaImprimible venta={venta} />}
          {mostrarInputsPago && (
            <div className="row mt-3">
                <h5>Información de Pago</h5>
                <div className="col-md-6">
                  <div className="form-group mt-3">
                      <label>Monto Recibido</label>
                      <input
                          type="number"
                          className="form-control"
                          placeholder="Ingrese el monto recibido"
                          value={montoRecibido}
                          onChange={(e) => {
                              const valor = parseFloat(e.target.value) || 0;
                              setMontoRecibido(valor);
                              setCambio(valor - total); // ✅ Calcular cambio automático
                          }}
                      />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mt-3">
                      <label>Cambio a Entregar</label>
                      <input
                          type="text"
                          className="form-control"
                          value={cambio >= 0 ? `$${Number(cambio).toFixed(2)}` : 'Monto insuficiente'}
                          readOnly
                      />
                  </div>
                </div>
            </div>
          )}
          {alerta.message && (
            <Alert variant={alerta.type} className="mt-3">
              {alerta.message}
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          {/* Botón Cancelar */}
          <Button variant="danger" onClick={cerrarModal}>
            <i className="fa-sharp fa-light fa-times-circle me-2"></i> Cancelar
          </Button>

          {/* Botón Limpiar */}
          <Button variant="warning" onClick={limpiarVenta}>
            <i className="fa-sharp fa-light fa-broom-wide me-2"></i> Limpiar
          </Button>

          {/* ✅ Mostrar FacturaPOS solo si invoicePOS tiene datos */}
          {invoicePOS && <FacturaPOS factura={invoicePOS} />}

          {/* Botón Finalizar Venta */}
          <Button
            variant="success"
            onClick={guardarVenta}
            disabled={finalizarDisabled || loadingVenta}
          >
            {loadingVenta ? (
              <>
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> Enviando...
              </>
            ) : (
              <>
                <i className="fa-sharp fa-light fa-paper-plane me-2"></i> Finalizar Venta
              </>
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="d-flex justify-content-end mt-4">
        <button className="btn btn-success" onClick={crearVenta}>
          <i className="fa-sharp fa-light fa-tag me-2"></i> Crear Venta
        </button>
      </div>
    </div>
  );
};

export default FormularioVenta;
