import React, { useState, useMemo } from 'react';
import { useTable, useFilters, useGlobalFilter, usePagination } from 'react-table';
import { Button } from 'react-bootstrap';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { GlobalFilter } from './GlobalFilter';

// Función para formatear valores como moneda
const formatearMoneda = (valor) => {
    if (valor === null || valor === undefined) return '-';
    return Number(valor).toLocaleString('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
};

const GananciasListTab = ({ sales }) => {
    // Función para exportar a Excel
    const exportToExcel = () => {
        const dataToExport = sales.flatMap(sale =>
            sale.dataItems.map(item => ({
                'Nombre Producto': item.name,
                'Cantidad': item.quantity,
                'Valor Base': item.baseValue * item.quantity,
                'Valor Venta': item.unitValue * item.quantity,
                'Ganancia': (item.unitValue * item.quantity) - (item.baseValue * item.quantity),
                'Fecha de Venta': new Date(sale.createdAt).toLocaleString(),
            }))
        );

        const worksheet = XLSX.utils.json_to_sheet(dataToExport);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Ganancias');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(blob, 'ganancias.xlsx');
    };

    // Definir las columnas de la tabla
    const columns = useMemo(() => [
        { Header: 'Nombre Producto', accessor: 'name' },
        { Header: 'Cantidad', accessor: 'quantity' },
        { Header: 'Valor Base', accessor: row => formatearMoneda(row.baseValue * row.quantity) },
        { Header: 'Valor Venta', accessor: row => formatearMoneda(row.unitValue * row.quantity) },
        { Header: 'Ganancia', accessor: row => formatearMoneda((row.unitValue * row.quantity) - (row.baseValue * row.quantity)) },
        { Header: 'Fecha de Venta', accessor: row => new Date(row.createdAt).toLocaleString() },
    ], [sales]);

    // Preparar los datos para la tabla
    const data = useMemo(() => sales.flatMap(sale => sale.dataItems.map(item => ({
        ...item,
        createdAt: sale.createdAt,
    }))), [sales]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        state,
        setGlobalFilter,
    } = useTable({ columns, data, initialState: { pageSize: 10 } }, useFilters, useGlobalFilter, usePagination);

    const { globalFilter } = state;

    return (
        <>
            <div className='row mt-3 mb-4 pe-0'>
                <div className='col-md-3 align-self-center'>
                    <label className="form-label my-0">Buscar...</label>
                </div>
                <div className='col-md-9 px-0'>
                    <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
                </div>
            </div>
            <div className="card">
                <div className="card-body p-0">
                    <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                        <div className="tbl-caption d-flex justify-content-between align-items-center">
                            <h4 className="heading mb-0">Ganancias</h4>
                            <Button className="btn btn-primary light btn-sm me-2" onClick={exportToExcel}>
                                Exportar Reporte
                            </Button>
                        </div>
                        <table {...getTableProps()} className="table">
                            <thead>
                                {headerGroups.map(headerGroup => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map(column => (
                                            <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {page.length > 0 ? (
                                    page.map(row => {
                                        prepareRow(row);
                                        return (
                                            <tr {...row.getRowProps()}>
                                                {row.cells.map(cell => (
                                                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                                ))}
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <tr>
                                        <td className='text-center my-5 py-5' colSpan={columns.length}>No hay ganancias disponibles</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <div className="d-sm-flex text-center justify-content-between align-items-center">
                            <div className="dataTables_info">
                                Mostrando {page.length > 0 ? 1 : 0} a {Math.min(page.length, 10)} de {data.length} entradas
                            </div>
                            <div className="dataTables_paginate paging_simple_numbers justify-content-center me-3">
                                <Button className="btn-sm paginate_button previous me-1" onClick={() => previousPage()} disabled={!canPreviousPage}>
                                    <i className="fa-solid fa-angle-left" />
                                </Button>
                                <Button className="btn-sm paginate_button next" onClick={() => nextPage()} disabled={!canNextPage}>
                                    <i className="fa-solid fa-angle-right" />
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default GananciasListTab;