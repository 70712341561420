import React, { useMemo } from 'react';

const GananciasResumen = ({ sales }) => {
    const totalGanancia = useMemo(() => {
        return sales.reduce((acc, sale) => {
            return acc + sale.dataItems.reduce((sum, item) => sum + ((item.unitValue * item.quantity) - (item.baseValue * item.quantity)), 0);
        }, 0);
    }, [sales]);

    return (
        <div className="card">
            <div className="card-body p-0">
                <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                    <div className="tbl-caption d-flex justify-content-between align-items-center p-3">
                        <h4 className="heading mb-0">Resumen de Ganancias</h4>
                    </div>
                    <table className="table ItemsCheckboxSec dataTable no-footer mb-2 mb-sm-0 table-striped">
                        <thead>
                            <tr>
                                <th className="px-3">Total Ganancia</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="text-end px-3">{totalGanancia.toLocaleString('es-CO', {
                                    style: 'currency',
                                    currency: 'COP',
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default GananciasResumen;