import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';

import { getSalesByCommerce, getSalesBySalesPoint } from '../../../services/VentaService';
import { getComercios } from '../../../services/ComerciosService';
import { getSalesPoints } from '../../../services/PuntosVentaService';
import { getProductosByCommerce } from '../../../services/ProductosService';
import GananciasListTab from '../AppsMenu/Ganancias/ListTab';
import GananciasResumen from '../AppsMenu/Ganancias/GananciasResumen';

const ListaGananciasAdmin = () => {
    const [sales, setSales] = useState([]);
    const [commerces, setCommerces] = useState([]);
    const [salesPoints, setSalesPoints] = useState([]);
    const [products, setProducts] = useState([]);
    const [selectedCommerce, setSelectedCommerce] = useState(null);
    const [selectedSalesPoint, setSelectedSalesPoint] = useState(null);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [startDate, setStartDate] = useState(moment().startOf('day'));
    const [endDate, setEndDate] = useState(moment().endOf('day'));
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));

    const userId = userDetails?.userData.id;
    const userRole = userDetails?.userData.role.role;

    useEffect(() => {
        if (userRole === 'administrador') {
            loadCommerces();
        } else if (userRole === 'usuario-comercio') {
            const commerceId = JSON.parse(localStorage.getItem('selectedCommerce')).value;
            const salesPointId = JSON.parse(localStorage.getItem('selectedSalesPoint')).value;
            setSelectedCommerce({ value: commerceId, label: 'Mi Comercio' });
            setSelectedSalesPoint({ value: salesPointId, label: 'Mi Punto de Venta' });

            loadProducts(commerceId);
            loadSalesData(commerceId, salesPointId);
        }
    }, [userId, userRole]);

    useEffect(() => {
        if (selectedCommerce) {
            loadSalesData(selectedCommerce.value, selectedSalesPoint?.value);
        }
    }, [selectedCommerce, selectedSalesPoint, selectedProducts, startDate, endDate]);

    const loadCommerces = async () => {
        try {
            const response = await getComercios(userId);
            const activeCommerces = response.data.map(commerce => ({
                value: commerce.id,
                label: commerce.name
            }));
            setCommerces(activeCommerces);

            if (activeCommerces.length === 1) {
                setSelectedCommerce(activeCommerces[0]);
                loadSalesPoints(activeCommerces[0].value);
                loadProducts(activeCommerces[0].value);
            }
        } catch (error) {
            console.error('Error al cargar comercios:', error);
        }
    };

    const loadSalesPoints = async (commerceId) => {
        try {
            const response = await getSalesPoints(commerceId);
            const activeSalesPoints = response.data.map(salesPoint => ({
                value: salesPoint.id,
                label: salesPoint.name
            }));
            setSalesPoints(activeSalesPoints);
        } catch (error) {
            console.error('Error al cargar puntos de venta:', error);
        }
    };

    const loadProducts = async (commerceId) => {
        try {
            const response = await getProductosByCommerce(commerceId);
            const productOptions = response.data.map(product => ({
                value: product.id,
                label: `${product.name} (${product.barcode})`
            }));
            setProducts(productOptions);
        } catch (error) {
            console.error('Error al cargar productos:', error);
        }
    };
    

    const loadSalesData = async (commerceId, salesPointId = null) => {
        try {
            const productIds = selectedProducts.map(p => p.value);
            const startDateISO = startDate.utc();
            const endDateISO = endDate.utc();

            let response;
            if (salesPointId) {
                response = await getSalesBySalesPoint(commerceId, salesPointId, startDateISO, endDateISO);
            } else {
                response = await getSalesByCommerce(commerceId, startDateISO, endDateISO);
            }

            let filteredSales = response.data || [];
            if (productIds.length > 0) {
                filteredSales = filteredSales.map(sale => ({
                    ...sale,
                    dataItems: sale.dataItems.filter(item => productIds.some(id => String(id) === String(item.idProduct)))
                })).filter(sale => sale.dataItems.length > 0);
            }

            setSales(filteredSales);
        } catch (error) {
            console.error('Error al cargar ventas:', error);
        }
    };

    const handleCommerceChange = (selectedCommerce) => {
        setSelectedCommerce(selectedCommerce);
        setSelectedSalesPoint(null);
        setSelectedProducts([]);
        loadSalesPoints(selectedCommerce.value);
        loadProducts(selectedCommerce.value);
    };

    const handleSalesPointChange = (selectedSalesPoint) => {
        setSelectedSalesPoint(selectedSalesPoint);
    };

    const handleProductChange = (selectedOptions) => {
        setSelectedProducts(selectedOptions || []);
    };

    const handleDateRangeChange = (event, picker) => {
        const newStartDate = picker.startDate.utc();
        const newEndDate = picker.endDate.utc();
        setStartDate(newStartDate);
        setEndDate(newEndDate);
    };

    return (
        <div className="container-fluid">
            <div className="row">
                {userRole === 'administrador' && (
                    <div className='row mt-3 mb-4 pe-0'>
                        <div className='col-md-3 align-self-center'>
                            <label className="form-label my-0">Filtrar por Comercio</label>
                        </div>
                        <div className='col-md-9 px-0'>
                            <Select
                                value={selectedCommerce}
                                onChange={handleCommerceChange}
                                options={commerces}
                                placeholder="Seleccionar Comercio"
                            />
                        </div>
                    </div>
                )}
                {selectedCommerce && salesPoints.length > 0 && (
                    <div className='row mt-0 mb-4 pe-0'>
                        <div className='col-md-3 align-self-center'>
                            <label className="form-label my-0">Filtrar por Punto de Venta</label>
                        </div>
                        <div className='col-md-9 px-0'>
                            <Select
                                value={selectedSalesPoint}
                                onChange={handleSalesPointChange}
                                options={salesPoints}
                                placeholder="Seleccionar Punto de Venta"
                            />
                        </div>
                    </div>
                )}
                {selectedCommerce && products.length > 0 && (
                    <div className='row mt-0 mb-4 pe-0'>
                        <div className='col-md-3 align-self-center'>
                            <label className="form-label my-0">Filtrar por Producto</label>
                        </div>
                        <div className='col-md-9 px-0'>
                            <Select
                                isMulti
                                value={selectedProducts}
                                onChange={handleProductChange}
                                options={products}
                                placeholder="Seleccionar Productos"
                            />
                        </div>
                    </div>
                )}
                <div className="row mt-0 mb-4 pe-0">
                    <div className='col-md-3 align-self-center'>
                        <label className="form-label my-0">Filtrar por Fecha</label>
                    </div>
                    <div className='col-md-9 px-0'>
                        <DateRangePicker
                            onApply={handleDateRangeChange}
                            initialSettings={{
                                startDate: startDate.format('YYYY-MM-DD'),
                                endDate: endDate.format('YYYY-MM-DD'),
                                locale: { format: 'YYYY-MM-DD' },
                            }}
                        >
                            <input type="text" className="form-control" placeholder="Seleccionar Rango de Fechas" />
                        </DateRangePicker>
                    </div>
                </div>
                <div className="col-md-12">
                    <GananciasResumen sales={sales} />
                </div>
                <div className="col-md-12">
                    <GananciasListTab sales={sales} />
                </div>
            </div>
        </div>
    );
};

export default ListaGananciasAdmin;