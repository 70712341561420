import React from 'react';

const FacturaPOS = ({ factura }) => {
  if (!factura) return null;

  const imprimirFactura = () => {
    const lineLength = 32; // Longitud de línea estándar para impresoras POS
    const separador = '-'.repeat(lineLength);

    const centrarTexto = (texto) => {
      const espacios = (lineLength - texto.length) / 2;
      return ' '.repeat(Math.max(0, espacios)) + texto;
    };

    const formatearLinea = (cantidad, descripcion, total) => {
      const cantidadStr = cantidad.toString();
      const totalStr = `$${total}`;
      const espacios = lineLength - cantidadStr.length - descripcion.length - totalStr.length;
      return `${cantidadStr} ${descripcion}${' '.repeat(Math.max(0, espacios))}${totalStr}`;
    };

    let contenido = `
${centrarTexto(factura.header)}
Fecha: ${factura.date}
${separador}
${centrarTexto(factura.commerce.name)}
NIT: ${factura.commerce.nit}
${factura.commerce.address}
Tel: ${factura.commerce.phone}
${separador}
Punto de Venta: ${factura.salesPoint}
Factura No: ${factura.saleNumber}
${separador}
Cliente: ${factura.customer.nombre || 'N/A'}
Doc: ${factura.customer.tipoDocumento} ${factura.customer.documento || 'N/A'}
Tel: ${factura.customer.telefono}
Correo: ${factura.customer.correo}
${separador}
Cant Descripción           Total
${separador}
`;

    factura.items.forEach((item) => {
      const descripcion = item.name.length > 16 ? item.name.substring(0, 16) : item.name;
      contenido += formatearLinea(item.quantity, descripcion, item.total) + '\n';
    });

    contenido += `${separador}
Subtotal:               $${factura.subtotal}
Impuestos:              $${factura.taxes}
TOTAL:                  $${factura.total}
${separador}
${centrarTexto('Gracias por su compra')}
`;

    const printWindow = window.open('', '_blank');
    printWindow.document.write(`<pre style="font-size:12px; margin:0;">${contenido}</pre>`);
    printWindow.document.close();
    printWindow.print();
    printWindow.close();
  };

  return (
    <button className="btn btn-info" onClick={imprimirFactura}>
      <i className="fa fa-print"></i> Imprimir POS
    </button>
  );
};

export default FacturaPOS;
