import { SVGICON } from "../../constant/theme";

const userDetails = JSON.parse(localStorage.getItem('userDetails'));
console.log("userDetails", userDetails);

let userRole =  null;
// Validamos si existe userDetails
if (userDetails && userDetails?.userData) {
  userRole = userDetails.userData.role.role;
} else {
  userRole = null;
}

export const MenuList = [
    {
        title: 'Adminsitración',
        classsChange: 'menu-title'
    },
    //Dashboard
    {
        title: 'Inicio',		
        iconStyle: 'fa-sharp fa-light fa-shop',
        to: '/inicio',
        allowedRoles: ['administrador', 'usuario-comercio']
    },
    {
        title: 'Ventas',
        classsChange: 'menu-title'
    },
    //ListarVentas
    {
        title: 'Ventas',		
        iconStyle: 'fa-sharp fa-light fa-list-dropdown',
        to: '/listar-ventas',
        allowedRoles: ['administrador', 'usuario-comercio']
    },
    //ListarGanancias
    {
        title: 'Ganancias',		
        iconStyle: 'fa-sharp fa-light fa-coin',
        to: '/ganancias',
        allowedRoles: ['administrador', 'usuario-comercio']
    },
    //CrearVenta
    {
        title: 'Crear Venta',		
        iconStyle: 'fa-sharp fa-light fa-cart-plus',
        to: '/crear-ventas',
        allowedRoles: ['usuario-comercio']
    },
    // Comercios - Solo para administrador
    {   
        title:'Comercios',
        iconStyle: 'fa-sharp fa-light fa-store',
        to: '/admin-comercios',
        allowedRoles: ['administrador'] // Solo permitido para administrador
    },
    // Puntos de Venta - Solo para administrador
    {   
        title:'Puntos de venta',
        iconStyle: 'fa-sharp fa-light fa-square-share-nodes',
        to: '/admin-puntos-venta',
        allowedRoles: ['administrador'] // Solo permitido para administrador
    },
    // Usuarios - Solo para administrador
    {   
        title:'Usuarios',
        iconStyle: 'fa-sharp fa-light fa-user-tie-hair',
        to: '/admin-usuarios-comercio',
        allowedRoles: ['administrador'] // Solo permitido para administrador
    },
    // Clientes - Solo para administrador
    {   
        title:'Clientes',
        iconStyle: 'fa-sharp fa-light fa-users',
        to: '/admin-clientes-comercio',
        allowedRoles: ['administrador'] // Solo permitido para administrador
    },
    {
        title: 'Inventario',
        classsChange: 'menu-title'
    },
    // Productos - Disponible para ambos roles
    {   
        title:'Productos',
        iconStyle: 'fa-sharp fa-light fa-basket-shopping',
        to: '/productos-comercio',
        allowedRoles: ['administrador', 'usuario-comercio'] // Permitido para ambos
    },
    // Servicios - Disponible para ambos roles
    {   
        title:'Servicios',
        iconStyle: 'fa-sharp fa-light fa-wand-magic-sparkles',
        to: '/servicios-comercio',
        allowedRoles: ['administrador', 'usuario-comercio'] // Permitido para ambos
    },
    {
        title: 'Mi Cuenta',
        classsChange: 'menu-title'
    },
    {   
        title:'Mi Perfil',
        iconStyle: 'fa-sharp fa-light fa-user-vneck',
        to: '/mi-perfil',
        allowedRoles: ['administrador', 'usuario-comercio'] // Permitido para ambos
    },
    {   
        title:'Cerrar Sesión',
        iconStyle: 'fa-sharp fa-light fa-person-walking-dashed-line-arrow-right', 
        to: '/login',
        allowedRoles: ['administrador', 'usuario-comercio'] // Permitido para ambos
    },
]