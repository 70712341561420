import React, { useState, useEffect, useRef } from "react";
import { Nav } from 'react-bootstrap'; 
import { findProductByNameOrBarcode, findServiceByName } from "../../../../services/VentaService";
import { getProductosBySalesPoint } from "../../../../services/ProductosService";
import { getServicesBySalesPoint } from "../../../../services/ServiciosService";

const BuscarProductosServicios = ({ agregarAlCarrito }) => {
  const [busqueda, setBusqueda] = useState("");
  const [resultados, setResultados] = useState([]);
  const [tipoBusqueda, setTipoBusqueda] = useState("producto");  
  const inputRef = useRef(null); 
  const resultadosRef = useRef(null);
  const timeoutRef = useRef(null); // Para manejar el debounce

  const getCommerceAndSalePointIds = () => {
    const commerceData = localStorage.getItem("selectedCommerce");
    const salePointData = localStorage.getItem("selectedSalesPoint");

    const commerceId = commerceData ? JSON.parse(commerceData).value : null;
    const salePointId = salePointData ? JSON.parse(salePointData).value : null;

    return { commerceId, salePointId };
  };

  useEffect(() => {
    const { commerceId, salePointId } = getCommerceAndSalePointIds();
    if (commerceId && salePointId) {
      cargarResultadosIniciales(commerceId, salePointId);
    }
  }, [tipoBusqueda]);

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      if (busqueda.trim() === "") {
        const { commerceId, salePointId } = getCommerceAndSalePointIds();
        if (commerceId && salePointId) {
          cargarResultadosIniciales(commerceId, salePointId);
        }
      } else {
        buscarProducto();
      }
    }, 500); // Espera 500ms antes de ejecutar la búsqueda

    return () => clearTimeout(timeoutRef.current);
  }, [busqueda]);

  const cargarResultadosIniciales = async (commerceId, salePointId) => {
    try {
      let resultados = [];
      if (tipoBusqueda === "producto") {
        const response = await getProductosBySalesPoint(salePointId);
        resultados = response.data || [];
      } else if (tipoBusqueda === "servicio") {
        const response = await getServicesBySalesPoint(salePointId);
        resultados = response.data || [];
      }
      setResultados(resultados);
    } catch (error) {
      console.error("Error al cargar resultados iniciales:", error);
    }
  };

  const buscarProducto = async () => {
    const { commerceId, salePointId } = getCommerceAndSalePointIds();
    if (!commerceId || !salePointId) return;

    try {
      let resultados = [];
      if (tipoBusqueda === "producto") {
        const response = await findProductByNameOrBarcode(busqueda.toLowerCase(), commerceId, salePointId);
        resultados = response || [];
      } else {
        const response = await findServiceByName(busqueda.toLowerCase(), commerceId, salePointId);
        resultados = response || [];
      }
      setResultados(Array.isArray(resultados) ? resultados : []);
    } catch (error) {
      console.error("Error en la búsqueda:", error);
    }
  };

  const agregarItem = (item) => {
    if (tipoBusqueda === "producto" && item.quantity <= 0) return;

    const itemConTipo = { ...item, type: tipoBusqueda === "producto" ? "product" : "service" };
    agregarAlCarrito(itemConTipo);
    setBusqueda(""); 
    inputRef.current.focus();

    const { commerceId, salePointId } = getCommerceAndSalePointIds();
    if (commerceId && salePointId) {
      cargarResultadosIniciales(commerceId, salePointId);
    }
  };

  return (
    <div className="buscar-productos-servicios">
      <div className="row justify-content-end">
        <div className="col-md-6">
          <Nav as="ul" className="nav nav-tabs dzm-tabs">
            <Nav.Item as="li" className="nav-item w-100">
              <Nav.Link
                as="button"
                className="w-100"
                style={{ borderRadius: '10px' }}
                active={tipoBusqueda === "producto"}
                onClick={() => setTipoBusqueda("producto")}
              >
                Producto
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" className="nav-item w-100">
              <Nav.Link
                as="button"
                className="w-100"
                style={{ borderRadius: '10px' }}
                active={tipoBusqueda === "servicio"}
                onClick={() => setTipoBusqueda("servicio")}
              >
                Servicio
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
      </div>

      <input
        type="text"
        className="form-control mt-3"
        placeholder={`Buscar ${tipoBusqueda === "producto" ? "Producto" : "Servicio"}`}
        value={busqueda}
        onChange={(e) => setBusqueda(e.target.value)}
        ref={inputRef}
      />

      <div 
        className="resultados-scroll mt-2"
        ref={resultadosRef}
        style={{
          maxHeight: '70vh',
          overflowY: 'auto',
          backgroundColor: '#ffffff',
          border: '1px solid #ddd',
          borderRadius: '8px',
          padding: '10px'
        }}
      >
        <ul className="list-group">
          {resultados.length > 0 ? resultados.map((item) => (
            <li 
              key={item.barcode || item.id} 
              className={`list-group-item d-flex justify-content-between align-items-center selector-products ${tipoBusqueda === "producto" && item.quantity === 0 ? 'disabled' : ''}`} 
              onClick={() => agregarItem(item)}
              style={{
                borderBottom: '1px solid #ddd',
                padding: '10px 15px',
                cursor: tipoBusqueda === "producto" && item.quantity <= 0 ? 'not-allowed' : 'pointer',
                opacity: tipoBusqueda === "producto" && item.quantity <= 0 ? 0.5 : 1,
              }}
            >
              <div className="d-flex align-items-center">
                {tipoBusqueda === "producto" ? (
                  <>
                    <img 
                      src={item.images && item.images.length ? item.images[0] : 'https://via.placeholder.com/50'} 
                      alt={item.name} 
                      style={{
                        width: '50px',
                        height: '50px',
                        borderRadius: '50%',
                        border: '2px solid #ddd',
                        marginRight: '10px',
                        backgroundColor: '#fff',
                      }} 
                    />
                    <div style={{ borderLeft: '2px solid #ddd' }} className="ps-3">
                      <strong className="text-capitalize">{item.name}</strong>
                      <p className="mb-0">Cantidad: {item.quantity}</p>
                      <p className="mb-0">Código de Barras: {item.barcode || '-'}</p>
                      <p className="mb-0">Precio de Venta: {formatearMoneda(item.salePrice)}</p>
                    </div>
                  </>
                ) : (
                  <div>
                    <strong className="text-capitalize">{item.name}</strong>
                    <p className="mb-0">Descripción: {item.description || '-'}</p>
                    <p className="mb-0">Comisión: {item.commissionPercentage || '0'}%</p>
                    <p className="mb-0">Precio de Venta: {formatearMoneda(item.salePrice)}</p>
                  </div>
                )}
              </div>
              <span>{formatearMoneda(item.salePrice)}</span>
            </li>
          )) : (
            <li className="list-group-item text-center">No hay resultados para mostrar</li>
          )}
        </ul>
      </div>
    </div>
  );
};

const formatearMoneda = (valor) => {
  return valor ? Number(valor).toLocaleString('es-CO', {
    style: 'currency',
    currency: 'COP',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }) : '-';
};

export default BuscarProductosServicios;